<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { authUserMethods } from "../../../state/helpers/authuser"
// import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import _ from 'lodash'
import {
  required,
} from "vuelidate/lib/validators";
import { propertyMethods } from "../../../state/helpers/property";
import { expensesCategoryMethods } from "../../../state/helpers/expensescategory";
import { expensesMethods } from "../../../state/helpers/expenses"
import { formOfPaymentMethods } from "../../../state/helpers/formofpayment";
import Swal from "sweetalert2";
import moment from 'moment';
import DatePicker from "vue2-datepicker";
import { PDFDocument  } from 'pdf-lib';
import { currencyMixin } from '../../mixins/currencyMixin';
import { formatCurrencyMixin } from '../../mixins/formatCurrencyMixin';
import { formatDateMixin } from "../../mixins/formatDateMixin";
import pdfImage from  '../../../assets/images/pdf.png';
import validateObjectMixin  from "../../mixins/validateObjectMixin";

/**
 * Blank page component
 */

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  page: {
    title: "Expenses",
    meta: [{ name: "description", content: appConfig.description }]
  },
  // components: { ckeditor: CKEditor.component, Layout },
  components: { Layout, DatePicker },
  mixins: [currencyMixin, formatCurrencyMixin, formatDateMixin, validateObjectMixin],
  data() {
    return {
      fileRecords: [],
      is_sort_asc: false,
      is_sort_ascdesc_enable: false,
      active_column: '',
      isBusy: false,
      pdfImage,
      uploadUrl: 'https://www.mocky.io/v2/5d4fb20b3000005c111099e3',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      errorText: {
        type: 'Invalid file type. Only images Allowed',
        size: 'Files should not exceed 10MB in size',
      },
      currentDate: new Date(),
      monthList: [],
      pdfBytes: null,
      pdfSrc: null,
      expenses: {
        date_paid: "",
        expense_amount: "",
        description: "",
        category_id: null,
        paid_to: null,
        paid_by: null,
        is_reimbursement_necessary: "no",
        reimbursement_date: null,
        proof_of_payment: null,
        property_id: null,

      },
      property: null,
      vendor: null,
      staff: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbarLocation: "bottom",
      },
      options: [],
      staffs: [],
      vendors: [],
      tab_items: [
        { id: 1, title: "Expense" },
        { id: 2, title: "Proof Of Payment" },
      ],
      title: "Expenses",
      is_tab_active: 1,
      is_item_active: true,
      is_update: false,
      submitted: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      page: 1,
      selected_month_year: null,
      start_date: null,
      end_date: null,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      can_login: false,
      expenses_items: [],
      categories: [],
      form_of_payments: [],
      payment_number_label: 'Payment Number',
      payment_date_label: 'Payment Date',
      deposit_by_label: 'Deposit By',
      amount: 0,
      receipt: {
        file: ''
      },
      fields: [
      { key: "property_name", label: "Property"},
      { key: "paid_to", label: "Paid To"},
        { key: "paid_by", label: "Paid By"},
        { key: "date_paid", label: "Date Paid"},
        
        { key: "expense_amount",  label: "Amount"},
        { key: "description" },
      ],
      search_options: [
        {id: 1, type: "Search expenses by date range(start date and end date) "},
        {id: 2, type: "Search expenses for date range with property."},
        {id: 3, type: "Search payment for date range with vendor."},
        {id: 4, type: "Search paymen by date range with property and vendor"},
        {id: 5, type: "Search payment for Month/Year"},
        {id: 6, type: "Search payment for Month/Year with property"},
        {id: 7, type: "Search payment for Month/Year with vendor"},
        {id: 8, type: "Search payment for Month/Year with property and vendor"},
        {id: 9, type: "Search payment for Property Only"},
        {id: 10, type: "Search payment for Vendor Only"},
      ],
      active_search_option: 1,
      month: null,
      year: null,
    };
  },
  validations: {
    expenses: {
      date_paid: {required},
      expense_amount: {required},
      category_id: {required},
      is_reimbursement_necessary: {required}
      },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    
    formattedExpensesAmount: {
      get() {
        const amount = parseFloat(this.expenses.expense_amount);
        if (!isNaN(amount)) {
          return "$" + amount.toFixed(2);
        }
        return this.expenses.expense_amount; // Return original input if not a valid number
      },
      set(value) {
        // Remove non-numeric characters except dot
        this.expenses.expense_amount = value.replace(/[^0-9.]/g, "");
      },
    },
  },

  mounted() {
    this.getAllExpenses()
    this.getAllExpensesCategory()
    this.getAllFormOfPayment()
    this.generateMonthList();
    // Set the initial number of items
    // this.totalRows = this.items?.length;
  },
  methods: {
    ...authUserMethods,
    ...propertyMethods,
    ...expensesMethods,
    ...expensesCategoryMethods,
    ...formOfPaymentMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    uploadFiles() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(
        this.uploadUrl,
        this.uploadHeaders,
        this.fileRecordsForUpload,
      )
      this.fileRecordsForUpload = []
    },
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord,
      )

      this.receipt = {
        file: ''
      }
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        fileRecord => !fileRecord.error,
      )
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords,
      )

      const file = this.fileRecordsForUpload
      const dom = this
      // eslint-disable-next-line func-names
      return (async function (file) {
        const data = await toBase64(file[0])
        dom.receipt.file = data
      }(file))
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
    async loadPDF() {
        
        const pdf  = process.env.VUE_APP_STORAGE_PATH + this.expenses.proof_of_payment;
     
  
          // Load the PDF file
          const response = await fetch(pdf);
        const pdfData = await response.arrayBuffer();
        this.pdfBytes = new Uint8Array(pdfData);
  
        // Create a PDF document instance
        const pdfDoc = await PDFDocument.load(this.pdfBytes);
  
        // Get the first page of the PDF
        // eslint-disable-next-line no-unused-vars
        const firstPage = pdfDoc.getPage(0);
  
        // Serialize the modified PDF back to bytes
        const modifiedPdfBytes = await pdfDoc.save();
  
        // Convert the modified PDF bytes to a base64 data URL
        this.pdfSrc = `data:application/pdf;base64,${Buffer.from(modifiedPdfBytes).toString('base64')}`;
        this.$bvModal.show('pdf-modal');
      },
      openPDF() {
        this.$bvModal.show('pdf-modal');
      },
      getFileName(file) {
        const fileData = file.split('/');
        const lastElement = fileData[fileData.length - 1];
        return lastElement;
    },
    getImage(items) {
            const image = items;
            if (image.length > 0) {
                return process.env.VUE_APP_STORAGE_PATH + image[0].file_name
            }
            return;
        },
    getAllExpenses() {
      return this.allExpenses({ paginate: true, page: this.page }).then((response) => {
        // eslint-disable-next-line no-console
        this.expenses_items = response.data.data
        this.totalRows = response.data.total
      })
    },
    handlePageChange(page) {
      const field = this.active_column
      this.is_sort_asc = !this.is_sort_asc
      let query;
      if(field != '') {
        query = { paginate: true,  ascdesc_sort: this.is_sort_asc ? 'asc' : 'desc', page, field}
      }else{
        query = { paginate: true, page }
      }


      return this.allExpenses(query).then((response) => {
        // eslint-disable-next-line no-console
        this.page = page
        this.expenses_items = response.data.data
        this.totalRows = response.data.total
      })
    },
    getAllExpensesCategory() {
      return this.allExpensesCategory({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console
        this.categories = response.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

      })
    },
    getAllFormOfPayment() {
      return this.allFormOfPayment({ paginate: false }).then((response) => {
        // eslint-disable-next-line no-console
        this.form_of_payments = response.data

      })
    },
    showModal() {
      this.$bvModal.show('app-modal')
      if(this.fileRecordsForUpload.length > 0) {
        this.$refs.vueFileAgent.deleteFileRecord(this.fileRecordsForUpload[0])
      }
    },
    hide() {
      this.getAllExpenses()
      this.$bvModal.hide('app-modal')
      this.is_update = false;
      // const tab_view = this.tab_items.filter(item => item.id !== 3);
      // this.tab_items = tab_view;
      if(this.fileRecordsForUpload.length > 0) {
        this.$refs.vueFileAgent.deleteFileRecord(this.fileRecordsForUpload[0])
      }
      this.property = null
      this.staff = null
      this.vendor = null
      this.is_tab_active = 1
      this.options = [],
      this.emptyData()
    },
    showFormContent(id) {
      this.is_tab_active = id;
    },
    showDetails(item) {
      const data = item

      this.is_update = true;
      this.showProperty(item.property_id).then((response) => {
        // eslint-disable-next-line no-console
        this.property = response.data
        this.expenses = data
        this.expenses.date_paid = moment(item.date_paid).toDate();

        if(this.expenses.is_reimbursement_necessary === "yes") {
          this.expenses.reimbursement_date = moment(item.reimbursement_date).toDate();
        }
        
        this.showModal()
      })

      this.showStaff(item.paid_by).then((response) => {
        this.staff = response.data;
      });


      this.showVendor(item.paid_to).then((response) => {
        this.vendor = response.data;
      });

      if (this.expenses.status === 'canceled') {
        this.is_item_active = false
      } else {
        this.is_item_active = true
      }
      // const tab_view = this.tab_items.filter(item => item.id === 3);
      // if(tab_view.length < 1) {
      //   this.tab_items.push({ id: 3, title: "Status" })
      // }

    },
    getFullDate(date) {
      return moment(date).format('Do MMMM, YYYY');
    },
    getHalfDate(date) {
      return moment(date).format('MMMM, YYYY');
    },
    saveExpenses() {
      this.submitted = true;
      // eslint-disable-next-line no-console
      const importantData = this.expenses;

      if(this.receipt.file === ""){
       importantData.proof_of_payment = null
      }else{
        delete importantData.proof_of_payment
      }
     
      // console.log(this.property)
      if(this.property === null){
        importantData.property_id = null
      }else{
        delete importantData.property_id;
      }

      if(this.staff === null){
        importantData.paid_by = null
      }else{
        delete importantData.paid_by ;
      }

      if(this.vendor === null){
        importantData.paid_to = null
      }else{
        delete importantData.paid_to;
      }
      
      if(this.expenses.is_reimbursement_necessary === 'yes'){
        if(importantData.reimbursement_date !== null) {
          delete importantData.reimbursement_date
        }
      }else{
        delete importantData.reimbursement_date
      }

      // if()
      const validateObject = this.validateObjectData(importantData)

     
      // stop here if form is invalid
      this.$v.$touch();
      if(validateObject.length > 0){return}

      if (this.$v.$invalid) {
        return;
      } else {
        const data = this.expenses
        data.property_id = this.property.id
        if(this.receipt.file !== "") {
          data.proof_of_payment = [this.receipt]
        }
        data.date_paid = moment(this.expenses.date_paid).format('YYYY-MM-DD');
        if(this.expenses.is_reimbursement_necessary === 'yes'){
          data.reimbursement_date = moment(this.expenses.reimbursement_date).format('YYYY-MM-DD');
        }

        data.paid_to = this.vendor.id
        data.paid_by = this.staff.id
        
        this.createExpenses(data).then(() => {
          this.hide()
          this.getAllExpenses()
        })
      }
      this.submitted = false;
    },
    updateExpensesDetails() {
      this.submitted = true;

       // eslint-disable-next-line no-console
       const importantData = this.expenses;

delete importantData.proof_of_payment
// console.log(this.property)
if(this.property === null){
  importantData.property_id = null
}else{
  delete importantData.property_id;
}

if(this.staff === null){
  importantData.paid_by = null
}else{
  delete importantData.paid_by ;
}

if(this.vendor === null){
  importantData.paid_to = null
}else{
  delete importantData.paid_to;
}

if(this.expenses.is_reimbursement_necessary === 'yes'){
  if(importantData.reimbursement_date !== null) {
    delete importantData.reimbursement_date
  }
}else{
  delete importantData.reimbursement_date
}

// if()
const validateObject = this.validateObjectData(importantData)

      // stop here if form is invalid
      this.$v.$touch();
      if(validateObject.length > 0){return}
      if (this.$v.$invalid) {
        return;
      } else {
        const data = this.expenses
        data.property_id = this.property.id
        delete data.proof_of_payment
        if(this.receipt.file !== "") {
          data.proof_of_payment = [this.receipt]
        }
     
        data.date_paid = moment(this.expenses.date_paid).format('YYYY-MM-DD');
        if(this.expenses.is_reimbursement_necessary === 'yes'){
          data.reimbursement_date = moment(this.expenses.reimbursement_date).format('YYYY-MM-DD');
        } else {
          delete data.reimbursement_date 
        }

        data.paid_to = this.vendor.id
        data.paid_by = this.staff.id
        this.updateExpenses(data).then(() => {
          this.hide()
         
        })
      }
      this.submitted = false;
    },
    emptyData() {
      this.expenses = {
        date_paid: "",
        expense_amount: "",
        description: "",
        category_id: null,
        paid_to: null,
        paid_by: null,
        is_reimbursement_necessary: "yes",
        reimbursement_date: null,
        proof_of_payment: null,
        property_id: null,
      }
      this.receipt = {
        file: ''
      }
      this.vendor = null;
      this.property = null;
      this.staff = null;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    // eslint-disable-next-line no-unused-vars
    search: _.debounce((loading, search, vm) => {
      vm.allProperty({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.options = response.data.data
        loading(false)
      })
    }, 100),
    onVendorSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchVendor(loading, search, this)
      }
    },
    // eslint-disable-next-line no-unused-vars
    searchVendor: _.debounce((loading, search, vm) => {
      vm.allVendors({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.vendors = response.data.data
        loading(false)
      })
    }, 100),
    onStaffSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchStaff(loading, search, this)
      }
    },
    // eslint-disable-next-line no-unused-vars
    searchStaff: _.debounce((loading, search, vm) => {
      vm.allStaffs({ paginate: true, keyword: search }).then((response) => {
        // eslint-disable-next-line no-console
        vm.staffs = response.data.data
        loading(false)
      })
    }, 100),
    formatMoney() {
      const parsedAmount = parseFloat(this.expenses.amount.replace(/,/g, ''));
      this.amount = isNaN(parsedAmount) ? 0 : parsedAmount;
    },
    toggleSwitch(item) {
      const data = this.expenses;
      // eslint-disable-next-line no-console
      if (item.value === true) {
        data.status = 'active'
      } else if (item.value === false) {
        data.status = 'canceled'
      }
      this.markAsCancel(this.expenses).then(() => {
        this.getAllExpenses()
      })
    },
    deleteExpensesItem() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteExpenses(this.expenses.id).then(() => {
            
            this.hide()
          })
        }
      });
    },
    

    filterExpensesWithProperty(data) {
      // eslint-disable-next-line no-console
      const active_search = this.active_search_option; 
      if (data === null) {
        return this.getAllPayment();
      }

      // eslint-disable-next-line no-console
      if(active_search == 9) {
        this.allExpensesForProperty({
        paginate: true,
        property_id: data.id,
        page: this.page,
      }).then((response) => {
        // eslint-disable-next-line no-console
        this.expenses_items = response.data.data;
        this.totalRows = response.data.total;
      });
      } else if(active_search == 2 || active_search == 4) {
        return this.getAllExpensesWithDateRange()
      } else if(active_search == 6 || active_search == 8) {
        return this.getAllExpensesWithMonthYear()
      }

    },
    filterExpensesWithVendor(data) {
      // eslint-disable-next-line no-console
      const active_search = this.active_search_option; 
      if (data === null) {
        return this.getAllExpenses();
      }
      if(active_search == 10) {
        return this.allExpensesForVendor({ vendor_id: data.id }).then(
        (response) => {
          // eslint-disable-next-line no-console
          this.expenses_items = response.data.data;
          this.totalRows = response.data.total;
        })
      } else if(active_search == 3 || active_search == 4) {
        return this.getAllExpensesWithDateRange()
      } else if(active_search == 7 || active_search == 8) {
        return this.getAllExpensesWithMonthYear()
      }
    },
    getAllExpensesWithDateRange(){
      let data;
      const active_search = this.active_search_option; 
      if(active_search == 1) {
        data = { start_date: this.start_date, end_date: this.end_date, page: this.page }
      } else if(active_search == 2) {
        if(this.property === null) return;
        data = { start_date: this.start_date, end_date: this.end_date, property_id: this.property.id, page: this.page }
      } else if(active_search == 3) {
        if(this.vendor == null) return;
        data = { start_date: this.start_date, end_date: this.end_date, vendor_id: this.vendor.id, page: this.page }
      } else if(active_search == 4){
        if(this.vendor === null || this.property === null) return;
        data = { start_date: this.start_date, end_date: this.end_date, vendor_id: this.vendor.id, property_id: this.property.id, page: this.page }
      }
      this.allExpensesWithDateRange(data).then((response) => {
        // eslint-disable-next-line no-console
        this.expenses_items = response.data.data;
        this.totalRows = response.data.total;
      });
    },
    getAllExpensesWithMonthYear(){
      let data;
      const active_search = this.active_search_option; 
      if(active_search == 5) {
        data = { month: this.month, year: this.year, page: this.page }
      } else if(active_search == 6) {
        if(this.property == null) return;
        data = { month: this.month, year: this.year, property_id: this.property.id , page: this.page}
      } else if(active_search == 7) {
        if(this.vendor === null) return;
        data = { month: this.month, year: this.year, vendor_id: this.vendor.id, page: this.page }
      } else if(active_search == 8){
        if(this.vendor === null || this.property === null) return;
        data = { month: this.month, year: this.year, vendor_id: this.vendor.id, property_id: this.property.id, page: this.page }
      }

      return this.allExpensesWithMonthYear(data).then((response) => {
        // eslint-disable-next-line no-console
        this.expenses_items = response.data.data;
        this.totalRows = response.data.total;
      });
    },

    filterExpensesWithStartDateEndDate() {
      // Clearing other filters
      // eslint-disable-next-line no-console

      // If both start_date and end_date are null, get all expenses
      if (this.start_date === null && this.end_date === null) {
        return this.getAllExpenses();
      }

      // If start_date is null, return early
      if (this.start_date === null) {
        return;
      }

      // If end_date is null, return early
      if (this.end_date === null) {
        return;
      }
 // Filter expenses based on the specified date range
      return this.getAllExpensesWithDateRange()
     
    },

    filterExpensesWithMonthYear(event) {
      // Clearing other filters

      // If selected_month_year is null, get all payments
      if (this.selected_month_year === null) {
        return this.getAllPayment();
      }

      // Extracting month and year from the selected_month_year
      const data = event.target.value;
      const dateArray = data.split('/');
      this.month = dateArray[0];
      this.year = dateArray[1];

      // Filter expenses based on the selected month and year
      return this.getAllExpensesWithMonthYear();
    },

    generateMonthList() {
      // Generating a list of months for navigation
      const startMonth = this.currentDate.getMonth();
      const startYear = this.currentDate.getFullYear();

      this.monthList = [];

      let currentMonth = startMonth;
      let currentYear = startYear;

      for (let i = 0; i < 13; i++) {
        const month = new Date(currentYear, currentMonth);
        this.monthList.push(moment(month).format('MMM/YYYY'));

        if (currentMonth === 11) {
          currentMonth = 0;
          currentYear++;
        } else {
          currentMonth++;
        }
      }
    },

    goToPreviousMonth() {
      // Navigating to the previous month
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1);
      this.selected_month_year = moment(this.currentDate).format("MMM-YYYY")
      this.generateMonthList();
    },

    goToNextMonth() {
      // Navigating to the next month
      this.currentDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 1);
      this.selected_month_year = moment(this.currentDate).format("MMM-YYYY")
      this.generateMonthList();
    },

    formatMonth(month) {
      // Formatting a month object to a readable string
      const options = { month: 'long', year: 'numeric' };
      const formattedDate = month.toLocaleDateString(undefined, options);
      const [formattedMonth, formattedYear] = formattedDate.split(' ');
      return `${formattedMonth} ${formattedYear}`;
    },
    handleSearchOption(event){
      // eslint-disable-next-line no-console
      this.active_search_option = event.target.value;
      this.property = null;
      this.vendor = null;
      this.start_date = null;
      this.end_date = null;
      this.selected_month_year = null;
      this.getAllExpenses();
    },
  async handleTableHeadClicked(item) {
      /*eslint-disable*/ 
      this.active_column = item
      this.is_sort_asc = !this.is_sort_asc
      this.isBusy = true

      let query = { paginate: true,  ascdesc_sort: this.is_sort_asc ? 'asc' : 'desc', page: this.page, field: item}
      await this.allExpenses(query).then((response) => {
        // eslint-disable-next-line no-console
        this.isBusy = false
        this.expenses_items = response.data.data
        this.totalRows = response.data.total

      })
  },
  handlePropertyChange(data) {
     /*eslint-disable*/ 
    if(data == null){
     
      this.property = null
    }
  },
  handleStaffChange(data) {
     /*eslint-disable*/ 
    if(data == null){
      this.staff = null
    }
  },
  handleVendorChange(data) {
     /*eslint-disable*/ 
    if(data == null){
      this.vendor = null
    }
  }
 }
}
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-11 col-lg-10">
        <b-card no-body class="mt-5">
      <b-card-body>
        <b-card-title class="d-flex mb-5 align-items-center justify-content-between">
          <h1 class="card-title">Expenses</h1>
          <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="showModal">Add New</a>
        </b-card-title>

        <!-- <b-card-title
              class="d-flex mb-3 align-items-center justify-content-between"
            >
            <div class="search_option_input_view">
              <label class="form-label mt-2" for="item1">Search Option for Expenses</label>
            <select v-model="active_search_option" class="form-control" @change="handleSearchOption">
              <option v-for="{id, type}  of search_options" :value="id" :key="id"> {{ type }} </option>
            </select>
            </div>

          </b-card-title> -->

          <!-- <div class="search_input_option_view">
            <div class="date_range_view" v-if="active_search_option == 1 || active_search_option == 2 ||
            active_search_option == 3 || active_search_option == 4">
              <div class="split">
                <label class="form-label mt-2" for="item1">Start Date</label>
                  <date-picker
                    id="item1"
                    v-model="start_date"
                    format="DD MMMM, YYYY"
                    class=""
                    lang="en"
                    @change="filterExpensesWithStartDateEndDate"
                  ></date-picker>
              </div>
              <div class="split">
                <label class="form-label mt-2" for="item1">End Date</label>
                  <date-picker
                    id="item1"
                    v-model="end_date"
                    format="DD MMMM, YYYY"
                    class=""
                    lang="en"
                    @change="filterExpensesWithStartDateEndDate"
                  ></date-picker>
              </div>
            </div>
            <div class="month_year_view" v-if="active_search_option == 5 ||  active_search_option == 6 || active_search_option == 7 || active_search_option == 8">
              <label class="form-label mt-2" for="item1">Month/Year</label>
              <div class="col-md-12 d-flex align-items-center">
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="goToPreviousMonth"
                  >
                    Prev Month
                  </button>
                  <select
                    id="item1"
                    v-model="selected_month_year"
                    class="form-select form-control join_select_input"
                    @change="filterExpensesWithMonthYear"
                  >
                    <option
                      v-for="(month, index) in monthList"
                      :key="index"
                      :value="month"
                      >{{ month }}</option
                    >
                  </select>
                  <button
                    type="button"
                    class="btn btn-outline-success"
                    @click="goToNextMonth"
                  >
                    Next Month
                  </button>
                </div>
            </div>
            <div class="property_search_view" v-if="
            active_search_option == 9 || active_search_option == 2 || active_search_option == 4 || active_search_option == 6 || active_search_option == 8">
              <label class="form-label mt-2" for="item1"
                    >Property</label
                  >
                  <v-select
                    v-model="property"
                    label="property_name"
                    :filterable="false"
                    :options="options"
                    @search="onSearch"
                    @input="filterExpensesWithProperty"
                    class=""
                  >
                    <template slot="no-options">
                      type to search property
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.property_name }}
                      </div>
                    </template>
                  </v-select>
            </div>
            <div class="tenant_search_view" v-if="active_search_option == 10 || active_search_option == 3 || active_search_option == 4 || active_search_option == 7 || active_search_option == 8">
              <label class="form-label mt-2" for="item1"
                    >Vendor</label>
                  <v-select
                    v-model="vendor"
                    label="full_name"
                    :filterable="false"
                    :options="vendors"
                    @search="onVendorSearch"
                    @input="filterExpensesWithVendor"
                    class=""
                  >
                    <template slot="no-options">
                      Type vendor email to search vendor
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.full_name }}
                      </div>
                    </template>
                  </v-select>
            </div>
          </div> -->

        <b-card-text>
          <!-- Table -->
          <div class="table-responsive mb-0 datatables expenses_table">
            <b-table :items="expenses_items" :fields="fields" responsive="sm"  @row-clicked="showDetails"
            id="app-table"
                striped="striped"
                borderless="borderless"
                 outlined="outlined"
                 fixed="fixed"
                 sticky-header="true"
                 :busy="isBusy"
                 @head-clicked="handleTableHeadClicked"
            >
            <template #head()="data">
        <div class="table_heading_view">
          <span class="">{{ data.label }}</span>
        <i class="fas fa-sort-amount-down-alt  text-success" id="sort-2-tooltip" v-if="is_sort_asc && data.column === active_column"></i>
        <i class="fas fa-sort-amount-up  text-info" id="sort-2-tooltip" v-if="!is_sort_asc && data.column === active_column"></i>
        </div>
        
      </template>
              <template #cell(date_paid)="data">
                {{ formDate(data.value) }}
              </template>
              <template #cell(expense_amount)="data">
                {{ formatCurrency(data.value) }}
              </template>
              <template #cell(paid_to)="data">
                {{ data.item.vendor_first_name }}  {{ data.item.vendor_last_name }}
              </template>
              <template #cell(paid_by)="data">
                {{ data.item.staff_first_name }}  {{ data.item.staff_last_name }}
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination @change="handlePageChange" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-card-text>



        <!-- user modal -->
        <b-modal id="app-modal" :title="is_update ? 'Update Expense Details' : 'Add Expense'"
          title-class="font-18" size="huge" hide-footer @hide="hide">
          <div class="bp_form_box">
            <div class="bp_form_side_bar">
              <div v-for="(data, index) in tab_items" :key="index" class="bp_tab_link"
                :class="is_tab_active === data.id ? 'bp_active' : ''" @click="showFormContent(data.id)">
                <div class="bp_tab_counter">
                  {{ index + 1 }}
                </div>
                <div class="bp_text_view">
                  <span> {{ data.title }} </span>
                </div>
              </div>
            </div>

            <div class="bp_form_view">
              <div class="form_content">
                <div class="tab_content" v-if="is_tab_active === 1">
                  <div class="row">
                    <div class="col-md-8">
                      <v-select
                      v-model="property"
                      label="property_name"
                      :filterable="false"
                      :options="options"
                      @search="onSearch"
                      class="borderless_input"
                      @input="handlePropertyChange"
                    >
                      <template slot="no-options">
                        type to search property
                      </template>
                      <template
                        slot="option"
                        slot-scope="option"
                      >
                        <div class="d-center">
                    
                          {{ option.property_name }}
                        </div>
                      </template>
                    </v-select>
                    <label class="form-label mt-3 mb-3" for="validationCustom04">Property</label>
                    </div>
                  </div>
                  <!-- row content start -->
                  <div class="row">
                    <div class="col-md-4" style="margin-top: -10px;">
                          <v-select
                            v-model="vendor"
                            label="full_name"
                            :filterable="false"
                            :options="vendors"
                            @search="onVendorSearch"
                            class="borderless_input"
                            @input="handleVendorChange"
                          >
                            <template slot="no-options">
                              search for vendor by name
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.full_name }}
                              </div>
                            </template>
                          </v-select>

                          <label class="form-label mt-2" for="item1"
                            >Paid to (Vendor)</label
                          >
                        </div>

                        <div class="col-md-4" style="margin-top: -10px;">
                          <v-select
                            v-model="staff"
                            label="full_name"
                            :filterable="false"
                            :options="staffs"
                            @search="onStaffSearch"
                            class="borderless_input"
                            @input="handleStaffChange"
                          >
                            <template slot="no-options">
                              search for staff by name
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.full_name }}
                              </div>
                            </template>
                          </v-select>

                          <label class="form-label mt-2" for="item1"
                            >Paid By (Staff)</label
                          >
                        </div>

                    <div class="col-md-4">
                
                      <date-picker id="item1" v-model="expenses.date_paid" 
                      :class="{
                        'is-invalid': submitted && $v.expenses.date_paid.$error,
                      }"
                      format="DD MMMM, YYYY" class="borderless_input" lang="en"></date-picker>
                      <!-- <input id="item1" class="form-control borderless_input" type="text"/> -->
                      <label class="form-label" for="item1">Date paid</label>
                      <div v-if="submitted && $v.expenses.date_paid.$error" class="invalid-feedback">
                      <span v-if="!$v.expenses.date_paid.required">This value is required.</span>
                    </div>
                    </div>

                  </div>
                  <!-- row content end -->


                  <!-- row content start -->
                  <div class="row">
                    <div class="col-md-4">
                      <input id="item1" v-model.lazy="formattedExpensesAmount" class="form-control borderless_input" 
                      :class="{
                        'is-invalid': submitted && $v.expenses.expense_amount.$error,
                      }"
                      type="text" 
                     
                      />
                      <label class="form-label" for="item1">Expense Amount</label>
                      <div v-if="submitted && $v.expenses.expense_amount.$error" class="invalid-feedback">
                      <span v-if="!$v.expenses.expense_amount.required">This value is required.</span>
                    </div>

                    </div>

                    <div class="col-md-2">

</div>
                    <div class="col-md-6">
                      <select id="item1" v-model="expenses.category_id" class="form-select form-control borderless_input"
                      :class="{
                        'is-invalid': submitted && $v.expenses.category_id.$error,
                      }"
                      >
                        <option v-for="(item, index) in categories" :key="index" :value="item.id">{{ item.name }}</option>
                      </select>

                      <label class="form-label" for="item1">Purpose of Expense </label>
                      <div v-if="submitted && $v.expenses.category_id.$error" class="invalid-feedback">
                      <span v-if="!$v.expenses.category_id.required">This value is required.</span>
                    </div>

                    </div>
                  </div>
                  <!-- row content end -->
            <!-- row content start -->
            <div class="row">

              <!-- <div class="col-md-5">
  <date-picker id="item1" v-model="expenses.statement" format="MMMM, YYYY" class="borderless_input"
  :class="{
    'is-invalid': submitted && $v.expenses.statement.$error,
  }"
  lang="en"></date-picker>
  <label class="form-label" for="item1">Statement</label>

  <div v-if="submitted && $v.expenses.statement.$error" class="invalid-feedback">
  <span v-if="!$v.expenses.statement.required">This value is required.</span>
</div>-->
<div class="col-md-4">
  <select id="item1" style="width: 30%;" v-model="expenses.is_reimbursement_necessary" class="form-select form-control borderless_input"
                      :class="{
                        'is-invalid': submitted && $v.expenses.is_reimbursement_necessary.$error,
                      }"
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>

                      <label class="form-label" for="item1">Is Reimbursement Necessary</label>
                      <div v-if="submitted && $v.expenses.is_reimbursement_necessary.$error" class="invalid-feedback">
                      <span v-if="!$v.expenses.is_reimbursement_necessary.required">This value is required.</span>
</div>


</div>

<div class="col-md-2"></div>
<div class="col-md-6" v-if="expenses.is_reimbursement_necessary === 'yes'">
  <date-picker id="item1" v-model="expenses.reimbursement_date" format="DD MMMM, YYYY" class="borderless_input"
  lang="en"></date-picker>
  <label class="form-label" for="item1">Reimbursement Date</label>

</div>
</div> 

<!-- row content end -->

                  <!-- row content start -->
                  <div class="row">
                    <div class="col-md-12">
                      <input id="item1" v-model="expenses.description" class="form-control borderless_input" type="text" />
                      <label class="form-label" for="item1">Description</label>
                    </div>

                  </div>
                  <!-- row content end -->



                </div>

                <div class="tab_content" v-if="is_tab_active === 2">
             
                  <div class="pdf_viewer_file_box" @click="loadPDF" v-if="is_update">
                    <img :src="pdfImage" alt="pdf" />
                    <h6>{{getFileName(expenses.proof_of_payment)}}</h6>
                    <div class="view_btn">View</div>
                  </div>
            
                  <b-modal id="pdf-modal" title="pdf"
          title-class="font-18" size="xl" hide-footer>
          <iframe :src="pdfSrc" frameborder="0" width="100%" height="900"></iframe>
        </b-modal>
                  <VueFileAgent ref="vueFileAgent" :theme="'list'" :multiple="false" :deletable="true" :meta="true"
                    :accept="'image/*,.pdf,.docx,.doc,.xlx,.csv'" :maxSize="'10MB'" :maxFiles="14"
                    :helpText="'Choose files'" :errorText="{
                      type: 'Invalid file type. Only images, pdf, docx, doc, xlx and csv Allowed',
                      size: 'Files should not exceed 10MB in size',
                    }" 
                    @select="filesSelected($event)" 
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)" 
                    v-model="fileRecords"
                    ></VueFileAgent>
                </div>
              </div>
              <div class="button_view d-flex align-items-center justify-content-between">
                <button type="button" class="btn btn-outline-secondary" @click="hide">Cancel</button>
                <button type="button" v-if="is_update" class="btn btn-outline-danger"
                  @click="deleteExpensesItem">Delete</button>
                <button type="button" class="btn btn-outline-success"
                  @click="is_update ? updateExpensesDetails() : saveExpenses()">Save</button>
              </div>

              <!-- <div class="button_view d-flex align-items-center justify-content-between" v-if="is_tab_active === 2">
            <button type="button" class="btn btn-outline-secondary" @click="hide">Cancel</button>
            <button type="button" class="btn btn-outline-success" @click="saveProfilePassword">Save</button>
          </div> -->

            </div>
          </div>
        </b-modal>

      </b-card-body>
    </b-card>
      </div>
    </div>

  </Layout>
</template>