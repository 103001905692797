import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const expensesComputed = {
    ...mapState('expenses', {
        expenses:(state) => state.expenses,
    }),
    ...mapGetters('expenses', ['getExpenses'])
}

// export property actions
export const expensesMethods = mapActions('expenses', ['allExpenses',
'allExpensesWithDateRange', 'allExpensesWithMonthYear', 'allExpensesForProperty', 'allExpensesForVendor',
'createExpenses', 'updateExpenses', 'markAsCancel', 'deleteExpenses',
])
